import React from 'react';

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <script src="https://assets.jointacademy.com/framework/script.js" />
    <script
      src="https://assets.calendly.com/assets/external/widget.js"
      type="text/javascript"
      async
    />
    <script
      src="https://cdn.optimizely.com/js/20574050108.js"
      type="text/javascript"
      async
    />
  </>
);
