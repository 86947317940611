// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-acces-anticipe-accorde-index-jsx": () => import("./../../../src/pages/acces-anticipe/accorde/index.jsx" /* webpackChunkName: "component---src-pages-acces-anticipe-accorde-index-jsx" */),
  "component---src-pages-acces-anticipe-index-jsx": () => import("./../../../src/pages/acces-anticipe/index.jsx" /* webpackChunkName: "component---src-pages-acces-anticipe-index-jsx" */),
  "component---src-pages-alignment-es-index-jsx": () => import("./../../../src/pages/alignment-es/index.jsx" /* webpackChunkName: "component---src-pages-alignment-es-index-jsx" */),
  "component---src-pages-apoteket-index-jsx": () => import("./../../../src/pages/apoteket/index.jsx" /* webpackChunkName: "component---src-pages-apoteket-index-jsx" */),
  "component---src-pages-apotekhjartat-index-jsx": () => import("./../../../src/pages/apotekhjartat/index.jsx" /* webpackChunkName: "component---src-pages-apotekhjartat-index-jsx" */),
  "component---src-pages-artroskorkort-index-jsx": () => import("./../../../src/pages/artroskorkort/index.jsx" /* webpackChunkName: "component---src-pages-artroskorkort-index-jsx" */),
  "component---src-pages-bcbsri-index-jsx": () => import("./../../../src/pages/bcbsri/index.jsx" /* webpackChunkName: "component---src-pages-bcbsri-index-jsx" */),
  "component---src-pages-bsca-index-jsx": () => import("./../../../src/pages/bsca/index.jsx" /* webpackChunkName: "component---src-pages-bsca-index-jsx" */),
  "component---src-pages-chenmed-index-jsx": () => import("./../../../src/pages/chenmed/index.jsx" /* webpackChunkName: "component---src-pages-chenmed-index-jsx" */),
  "component---src-pages-confirmation-index-jsx": () => import("./../../../src/pages/confirmation/index.jsx" /* webpackChunkName: "component---src-pages-confirmation-index-jsx" */),
  "component---src-pages-crconfirmation-index-jsx": () => import("./../../../src/pages/crconfirmation/index.jsx" /* webpackChunkName: "component---src-pages-crconfirmation-index-jsx" */),
  "component---src-pages-developers-index-jsx": () => import("./../../../src/pages/developers/index.jsx" /* webpackChunkName: "component---src-pages-developers-index-jsx" */),
  "component---src-pages-doktorse-index-jsx": () => import("./../../../src/pages/doktorse/index.jsx" /* webpackChunkName: "component---src-pages-doktorse-index-jsx" */),
  "component---src-pages-download-index-jsx": () => import("./../../../src/pages/download/index.jsx" /* webpackChunkName: "component---src-pages-download-index-jsx" */),
  "component---src-pages-early-access-granted-index-jsx": () => import("./../../../src/pages/early-access/granted/index.jsx" /* webpackChunkName: "component---src-pages-early-access-granted-index-jsx" */),
  "component---src-pages-early-access-humana-index-jsx": () => import("./../../../src/pages/early-access/humana/index.jsx" /* webpackChunkName: "component---src-pages-early-access-humana-index-jsx" */),
  "component---src-pages-early-access-index-jsx": () => import("./../../../src/pages/early-access/index.jsx" /* webpackChunkName: "component---src-pages-early-access-index-jsx" */),
  "component---src-pages-euroaccident-index-jsx": () => import("./../../../src/pages/euroaccident/index.jsx" /* webpackChunkName: "component---src-pages-euroaccident-index-jsx" */),
  "component---src-pages-evryhealth-index-jsx": () => import("./../../../src/pages/evryhealth/index.jsx" /* webpackChunkName: "component---src-pages-evryhealth-index-jsx" */),
  "component---src-pages-framsteg-bronze-jsx": () => import("./../../../src/pages/framsteg/bronze.jsx" /* webpackChunkName: "component---src-pages-framsteg-bronze-jsx" */),
  "component---src-pages-framsteg-gold-jsx": () => import("./../../../src/pages/framsteg/gold.jsx" /* webpackChunkName: "component---src-pages-framsteg-gold-jsx" */),
  "component---src-pages-framsteg-silver-jsx": () => import("./../../../src/pages/framsteg/silver.jsx" /* webpackChunkName: "component---src-pages-framsteg-silver-jsx" */),
  "component---src-pages-fysiomassan-index-jsx": () => import("./../../../src/pages/fysiomassan/index.jsx" /* webpackChunkName: "component---src-pages-fysiomassan-index-jsx" */),
  "component---src-pages-get-started-index-jsx": () => import("./../../../src/pages/get-started/index.jsx" /* webpackChunkName: "component---src-pages-get-started-index-jsx" */),
  "component---src-pages-healthintegrator-index-jsx": () => import("./../../../src/pages/healthintegrator/index.jsx" /* webpackChunkName: "component---src-pages-healthintegrator-index-jsx" */),
  "component---src-pages-humana-index-jsx": () => import("./../../../src/pages/humana/index.jsx" /* webpackChunkName: "component---src-pages-humana-index-jsx" */),
  "component---src-pages-idrottsskadeexperten-index-jsx": () => import("./../../../src/pages/idrottsskadeexperten/index.jsx" /* webpackChunkName: "component---src-pages-idrottsskadeexperten-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-keymedical-index-jsx": () => import("./../../../src/pages/keymedical/index.jsx" /* webpackChunkName: "component---src-pages-keymedical-index-jsx" */),
  "component---src-pages-kom-igang-index-jsx": () => import("./../../../src/pages/kom-igang/index.jsx" /* webpackChunkName: "component---src-pages-kom-igang-index-jsx" */),
  "component---src-pages-ladda-ner-index-jsx": () => import("./../../../src/pages/ladda-ner/index.jsx" /* webpackChunkName: "component---src-pages-ladda-ner-index-jsx" */),
  "component---src-pages-lvl-up-index-jsx": () => import("./../../../src/pages/lvl-up/index.jsx" /* webpackChunkName: "component---src-pages-lvl-up-index-jsx" */),
  "component---src-pages-medicare-index-jsx": () => import("./../../../src/pages/medicare/index.jsx" /* webpackChunkName: "component---src-pages-medicare-index-jsx" */),
  "component---src-pages-miamidade-index-jsx": () => import("./../../../src/pages/miamidade/index.jsx" /* webpackChunkName: "component---src-pages-miamidade-index-jsx" */),
  "component---src-pages-patient-referral-index-jsx": () => import("./../../../src/pages/patient-referral/index.jsx" /* webpackChunkName: "component---src-pages-patient-referral-index-jsx" */),
  "component---src-pages-projetsante-index-jsx": () => import("./../../../src/pages/projetsante/index.jsx" /* webpackChunkName: "component---src-pages-projetsante-index-jsx" */),
  "component---src-pages-public-patient-fr-[lang]-jsx": () => import("./../../../src/pages/public/patient/fr/[lang].jsx" /* webpackChunkName: "component---src-pages-public-patient-fr-[lang]-jsx" */),
  "component---src-pages-public-patient-se-[lang]-jsx": () => import("./../../../src/pages/public/patient/se/[lang].jsx" /* webpackChunkName: "component---src-pages-public-patient-se-[lang]-jsx" */),
  "component---src-pages-public-patient-us-[lang]-jsx": () => import("./../../../src/pages/public/patient/us/[lang].jsx" /* webpackChunkName: "component---src-pages-public-patient-us-[lang]-jsx" */),
  "component---src-pages-salesforce-demo-jsx": () => import("./../../../src/pages/salesforce/demo.jsx" /* webpackChunkName: "component---src-pages-salesforce-demo-jsx" */),
  "component---src-pages-se-sv-patient-index-jsx": () => import("./../../../src/pages/se/sv/patient/index.jsx" /* webpackChunkName: "component---src-pages-se-sv-patient-index-jsx" */),
  "component---src-pages-silverlife-index-jsx": () => import("./../../../src/pages/silverlife/index.jsx" /* webpackChunkName: "component---src-pages-silverlife-index-jsx" */),
  "component---src-pages-skadekompassen-index-jsx": () => import("./../../../src/pages/skadekompassen/index.jsx" /* webpackChunkName: "component---src-pages-skadekompassen-index-jsx" */),
  "component---src-pages-smartsenior-index-jsx": () => import("./../../../src/pages/smartsenior/index.jsx" /* webpackChunkName: "component---src-pages-smartsenior-index-jsx" */),
  "component---src-pages-sms-inbjudan-index-jsx": () => import("./../../../src/pages/sms-inbjudan/index.jsx" /* webpackChunkName: "component---src-pages-sms-inbjudan-index-jsx" */),
  "component---src-pages-sportsrehab-index-jsx": () => import("./../../../src/pages/sportsrehab/index.jsx" /* webpackChunkName: "component---src-pages-sportsrehab-index-jsx" */),
  "component---src-pages-synergy-index-jsx": () => import("./../../../src/pages/synergy/index.jsx" /* webpackChunkName: "component---src-pages-synergy-index-jsx" */),
  "component---src-pages-test-braze-jsx": () => import("./../../../src/pages/test/braze.jsx" /* webpackChunkName: "component---src-pages-test-braze-jsx" */),
  "component---src-pages-uk-trial-index-jsx": () => import("./../../../src/pages/uk-trial/index.jsx" /* webpackChunkName: "component---src-pages-uk-trial-index-jsx" */),
  "component---src-pages-us-en-patient-index-jsx": () => import("./../../../src/pages/us/en/patient/index.jsx" /* webpackChunkName: "component---src-pages-us-en-patient-index-jsx" */),
  "component---src-pages-varden-index-jsx": () => import("./../../../src/pages/varden/index.jsx" /* webpackChunkName: "component---src-pages-varden-index-jsx" */),
  "component---src-pages-vivolcab-index-jsx": () => import("./../../../src/pages/vivolcab/index.jsx" /* webpackChunkName: "component---src-pages-vivolcab-index-jsx" */),
  "component---src-pages-zoom-register-test-jsx": () => import("./../../../src/pages/zoom/register-test.jsx" /* webpackChunkName: "component---src-pages-zoom-register-test-jsx" */)
}

